import Vue from 'vue'
import Vuex from 'vuex'

import localforage from 'localforage'

Vue.use(Vuex)

localforage.config({
	name: 'Cache Web Saturne',
})

/*
 * This class implements a storage that is super volatile (will update in Vue on change),
 * but it still restored during the session using sessionStorage.
 */

const globalStore = new Vuex.Store({
	state: {
		// User data: get init from session
		user: {
			name: undefined,
			username: undefined,
		},
		// Show tabs
		show_fops_tab: false,
		show_events_tab: false,
		show_admin_tabs: false,
	},
	mutations: {
		_set(state, payload) {
			Vue.set(state, payload.key, payload.val)
		},
		resetUser(state) {
			state.user = {
				name: "",
				username: "",
			}
			state.show_fops_tab = false
			state.show_admin_tabs = false
			state.show_events_tab = false
			localforage.removeItem("user")
			localforage.removeItem("show_fops_tab")
			localforage.removeItem("show_events_tab")
			localforage.removeItem("show_admin_tabs")
		},

	},
	getters: {
		authenticated: state => {
			return Boolean(state.user) && Boolean(state.user.username)
		},
		getName: (state, getters) => {
			return getters.authenticated && state.user.name
		},
		getUserName: (state, getters) => {
			return getters.authenticated && state.user.username
		},
		getShowFopsTab: (state, getters) => {
			return getters.authenticated && state.show_fops_tab
		},
		getShowEventsTab: (state, getters) => {
			return getters.authenticated && state.show_events_tab
		},
		getShowAdminTabs: (state, getters) => {
			return getters.authenticated && state.show_admin_tabs
		}
	},
	actions: {
		set(context, payload) {
			context.commit('_set', payload);
			if (payload.store || payload.store == undefined)
				return localforage.setItem(payload.key, JSON.stringify(payload.val));
			return Promise.resolve();
		},
		initialization(context) {
			// This function is called BEFORE Vue is even loaded. (main.js)
			return new Promise(async (resolve, reject) => {
				try {
					await localforage.getItem("user").then(val => {
						return context.dispatch('set', { key: "user", val: JSON.parse(val), store: false });
					});
					await localforage.getItem("show_fops_tab").then(val => {
						return context.dispatch('set', { key: "show_fops_tab", val: JSON.parse(val), store: false });
					});
					await localforage.getItem("show_events_tab").then(val => {
						return context.dispatch('set', { key: "show_events_tab", val: JSON.parse(val), store: false });
					});
					await localforage.getItem("show_admin_tabs").then(val => {
						return context.dispatch('set', { key: "show_admin_tabs", val: JSON.parse(val), store: false });
					});
					resolve();
				} catch (err) {
					reject(err);
				}
			});
		},
		setInitData(context, data) {
			return new Promise(async (resolve, reject) => {
				try {
					await context.dispatch('set', { key: 'user', val: data.user });
					await context.dispatch('set', { key: 'show_fops_tab', val: data.show_fops_tab });
					await context.dispatch('set', { key: 'show_events_tab', val: data.show_events_tab });
					await context.dispatch('set', { key: 'show_admin_tabs', val: data.show_admin_tabs });
					resolve();
				} catch (err) {
					reject(err);
				}
			});
		}
	},
})

function setInitData(data) {
	return globalStore.dispatch('setInitData', data);
}
function clearLogin() {
	globalStore.commit('resetUser')
}


export {
	globalStore,
	setInitData,
	clearLogin,
}