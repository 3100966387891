import Vue from 'vue'
import vuetify from './plugins/vuetify'

import './assets/main.css'

// axios
import axios from 'axios'
import VueAxios from 'vue-axios'
axios.defaults.withCredentials = true
Vue.use(VueAxios, axios)

// loadash
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
Vue.use(VueLodash, { lodash: lodash })

import AxiosWrappers from "./plugins/axiosWrappers"
Vue.use(AxiosWrappers)

import router from './router/router'
import App from './App.vue'

// Add filters
import "./plugins/filters"

// Handle alert snackbars and modals
Vue.prototype.$alert = new Vue()

// VUEX
import { globalStore } from "@/utils/storage"

globalStore.dispatch('initialization').then(() => {
	new Vue({
		vuetify,
		router,
		store: globalStore,
		render: h => h(App),
	}).$mount('#app')
})