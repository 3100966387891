import Vue from "vue"

Vue.filter('prettyDate', date => {
	let d = new Date(date)
	if (!d) return ""
	return d.toLocaleDateString("fr-FR", {
		weekday: "long",
		year: "numeric",
		month: "long",
		day: "numeric",
		hour: 'numeric',
		minute: 'numeric',
	})
})

Vue.filter('spanDate', dates => {
	let start_date = new Date(dates.start)
	let end_date = new Date(dates.end)
	if (!start_date || !end_date) return ""
	let day = start_date.toLocaleDateString("fr-FR", {
		weekday: "long",
		year: "numeric",
		month: "long",
		day: "numeric",
	})

	let start_hours = ("0" + start_date.getHours()).slice(-2) +
		':' + ("0" + start_date.getMinutes()).slice(-2)
	let end_hours = ("0" + end_date.getHours()).slice(-2) +
		':' + ("0" + end_date.getMinutes()).slice(-2)

	return day + ' ' + start_hours + ' - ' + end_hours
})

Vue.filter('regularDate', date => {
	let d = new Date(date)
	if (!d) return ""
	return d.toLocaleDateString("fr-FR", {
		year: "numeric",
		month: "long",
		day: "numeric",
	})
})

Vue.filter('capitalize', value => {
	if (!value) return ''
	value = value.toString()
	return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('displayList', list => {
	if (!list) return ''
	return list.join(", ")
})

Vue.filter('ellipisize', (value, maxSize) => {
	if (value.length >= maxSize) {
		return value.slice(0, maxSize) + "...";
	}
	return value;
})