<template>
  <v-snackbar
    v-model="snackbar"
    :color="color"
    :timeout="6000"
    class="ma-4"
  >
    <v-row align="center">
      <v-col cols="auto">
        {{ message }}
      </v-col>
      <v-col
        v-if="close_btn"
        cols="auto"
      >
        <v-btn
          dark
          outlined
          @click.stop="snackbar = false"
        >
          Fermer
        </v-btn>
      </v-col>
    </v-row>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      snackbar: false,
      color: "",
      message: "",
      close_btn: true,
    };
  },
  created() {
    this.$alert.$on("snackbar", (opt) => {
      this.message = opt.message;
      this.color = opt.status;
      if(opt.close_btn == undefined) opt.close_btn = true;
      this.close_btn = opt.close_btn;
      this.snackbar = true;
    });
  },
};
</script>
