<template>
  <v-dialog
    v-model="dialog"
    max-width="490"
  >
    <v-card>
      <v-card-title class="headline">
        {{ title }}
      </v-card-title>

      <v-card-text>{{ message }}</v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="error"
          text
          @click="done('yes')"
        >
          Oui
        </v-btn>
        <v-btn
          outlined
          text
          @click="done('no')"
        >
          Non
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: function () {
    return {
      dialog: false,
      title: "",
      message: "",
      callback: null,
    };
  },
  created: function () {
    let that = this;
    this.$alert.$on("confirm", (opt) => {
      that.title = opt.title;
      that.message = opt.message;
      that.callback = opt.callback;
      that.dialog = true;
    });
  },
  methods: {
    done(ok) {
      this.dialog = false;
      if (ok == "yes") {
        this.callback();
      }
      this.callback = null;
    },
  },
};
</script>
