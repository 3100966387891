<template>
  <v-app>
    <Navigation :page-title="routeName" />

    <v-main>
      <!-- MAIN ROUTER VIEW -->
      <v-container
        id="main"
        fluid
        class="overflow-y-auto fill-height d-block"
      >
        <!-- Ces tags sont importants car ils permettent de détecter le scrolling -->
        <transition name="slide-fade">
          <router-view />
        </transition>
      </v-container>
    </v-main>

    <!-- Alerts -->
    <Confirm />
    <Snackbar />
  </v-app>
</template>

<script>
import Confirm from "@/components/Utils/Confirm";
import Snackbar from "@/components/Utils/Snackbar";
import Navigation from "@/components/Utils/Navigation";

export default {
  name: "App",
  components: {
    Confirm,
    Snackbar,
    Navigation,
  },
  computed: {
    routeName() {
      return this.$route.meta.name;
    },
  },
};
</script>

<style>
html,
body {
  height: 100%;
  overflow: hidden; /* Prevent out of page scrolling (better for UIs with vertical bars: eg. outlook.com) */
  background-color: rgb(39, 39, 39); /* Out of page (scrolling) color */
}
.v-main {
  background-color: var(--v-appback-base);
}
.v-application,
.v-main {
  height: 100%;
}
/* Transitions between router views */
.slide-fade-enter-active {
  transition: all 0.1s ease 0.2s;
}
.slide-fade-leave-active {
  transition: all 0.2s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
}
</style>