/*
 * Login methods.
 */
import axios from 'axios'
import { setInitData, clearLogin } from '@/utils/storage'

const baseUrl = location.protocol + "//" + location.hostname + (location.port && ":" + location.port)

export function _authCheck() {
	return axios.get(process.env.VUE_APP_API_URL + '/checkLogin', { withCredentials: true })
	.then(response => {
		return setInitData(response.data)
	})
}

function performLogin(to) {
	/*
     * to: the return url
     */
	// Using redirection
	let res_url = baseUrl + '/login?logged_in=1'
	if (to && to != '/') res_url += '&next=' + encodeURIComponent(to)
	window.location.replace(
		process.env.VUE_APP_API_URL +
        '/login' +
        '?next=' +
        encodeURIComponent(res_url)
	)
}

export function loginCheck(to, next) {
	return _authCheck(to, next).then(() => {
		next(to)
	}).catch(error => {
		if (error.response) {
			if (error.response.status == 401) {
				clearLogin()
				performLogin(to)
			} else if (error.response.status == 403) {
				next('/403')
			} else {
				console.log(error)
			}
		} else {
			console.log(error)
			// Erreur de connexion
			next('/408')
		}
	})
}

export function detectLogin() {
	return _authCheck()
}