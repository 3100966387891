/*
 * Wrapper around axios to handle classic errors (401, 403)
 */

import semver from 'semver'
import { clearLogin } from '../utils/storage'

function axiosPost(...names) {
	return this.axios.post(...names).catch(err => {
		if (err.response) {
			if (err.response.status == 401) {
				clearLogin()
				return new Promise(() => this.$router.replace(
					{
						name: "login",
						query: { err: err.response.data },
						params: { to: this.$route.fullPath },
					}
				))
			} else if (err.response.status == 403) {
				return new Promise(() => this.$router.replace({ path: "/403" }))
			} else if (err.response.status == 404) {
				return new Promise(() => this.$router.replace({ path: "/404" }))
			}
		} else if (err.isAxiosError) {
			return new Promise(() => this.$router.push({ path: "/408" }))
		}
		throw err
	})
}
const AxiosWrappers = {
	install(Vue) {
		if (semver.lt(Vue.version, '3.0.0')) {
			Object.defineProperties(Vue.prototype, {
				axiosPost: {
					get: function get() {
						return axiosPost
					},
				},
			})
		} else {
			Vue.config.globalProperties.axiosPost = axiosPost
		}
		Vue.axiosPost = axiosPost
	},
}

export default AxiosWrappers