import Vue from 'vue'
import Router from 'vue-router'

import { detectLogin } from "@/utils/login"

// We use import() to load pages to support code splitting. See
// https://webpack.js.org/guides/code-splitting/#dynamic-imports

const Login = () => import('@/views/Login');
const Logout = () => import('@/views/Logout');

const About = () => import('@/views/About');
const Home = () => import('@/views/Home');
const MyList = () => import('@/views/MyList');
const AddEditEvent = () => import('@/components/Event/AddEditEvent');
const MyGroups = () => import('@/views/MyGroups');
const Calendar = () => import('@/views/Calendar');

// Admins
const GroupManager = () => import('@/views/Admin/GroupManager');
const FopTypeManager = () => import('@/views/Admin/FopTypeManager');
const LocationManager = () => import('@/views/Admin/LocationManager');
const FopStepsManager = () => import('@/views/Admin/FopStepsManager');

const Error403 = () => import('@/views/ErrorPages/403');
const Error404 = () => import('@/views/ErrorPages/404');
const Error408 = () => import('@/views/ErrorPages/408');
const Error500 = () => import('@/views/ErrorPages/500');

Vue.use(Router)

const router = new Router({
	routes: [
		// Main
		{
			path: '/',
			component: Home,
		},
		{
			path: '/myfops',
			component: MyList,
			props: {
				listtype: 'fop',
			},
		},
		{
			path: '/myevents',
			component: MyList,
			props: {
				listtype: 'event',
			},
		},
		{
			path: '/myevents/create',
			component: AddEditEvent,
		},
		{
			name: "EventEdit",
			path: '/myevents/edit',
			component: AddEditEvent,
			props: (route) => ({
				edit: true,
				event_id: route.params.event_id,
			}),
		},
		{
			path: '/mygroups',
			component: MyGroups,
		},
		{
			path: '/calendar',
			component: Calendar,
		},
		{
			path: '/mygroups',
			component: MyGroups,
		},
		{
			path: '/groups/manage',
			component: GroupManager,
		},
		{
			path: '/foptypes/manage',
			component: FopTypeManager,
		},
		{
			path: '/locations/manage',
			component: LocationManager,
		},
		{
			path: '/fopsteps/manage',
			component: FopStepsManager,
		},
		{
			path: '/about',
			component: About,
		},
		// LOGIN
		{
			path: '/login',
			name: 'login',
			component: Login,
			props: true,
			beforeEnter: (to, from, next) => {
				if (to.query.logged_in) {
					detectLogin().then(() => {
						return next(to.query.next || '/')
					}).catch(() => {
						next()
					})
				} else {
					next()
				}
			},
			meta: {
				name: "Connexion",
			},
		},
		{
			path: '/logout',
			name: 'logout',
			component: Logout,
		},
		// ERROR pages
		{
			path: '/403',
			component: Error403,
		},
		{
			path: '/404',
			component: Error404,
		},
		{
			path: '/408',
			component: Error408,
		},
		{
			path: '/500',
			component: Error500,
		},
		{
			path: "*",
			component: Error404,
		},
	],
	mode: 'history',
})

export default router
