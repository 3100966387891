<template>
  <!-- TOP BAR -->
  <div>
    <v-app-bar
      dark
      app
      clipped-left
      color="#293b4d"
      class="mb-4"
    >
      <v-app-bar-nav-icon
        v-show="miniScreen"
        @click="drawer = true"
      />

      <v-img
        class="mx-4"
        :src="require('../../assets/saturne_logo.png')"
        max-height="40"
        max-width="40"
        contain
      />

      <v-toolbar-title class="headline text-uppercase">
        <span>SATURNE</span>
      </v-toolbar-title>

      <v-spacer />

      <!-- DEBUG -->
      <!-- <v-btn
        class="mr-1"
        @click="becomeUsergroup('VE')"
      >
        VE
      </v-btn>
      <v-btn
        class="mr-1"
        @click="becomeUsergroup('D')"
      >
        D
      </v-btn>
      <v-btn
        class="mr-1"
        @click="becomeUsergroup('DaTA')"
      >
        DaTA
      </v-btn>
      <v-btn
        class="mr-1"
        @click="becomeUsergroup('EVENT')"
      >
        EVENT
      </v-btn> -->

      <v-btn
        to="/logout"
        icon
      >
        <v-icon color="error">
          mdi-logout
        </v-icon>
      </v-btn>
    </v-app-bar>
    <!-- MENU -->
    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
      :temporary="miniScreen"
      :permanent="!miniScreen"
      :mini-variant="!miniScreen"
      :expand-on-hover="!miniScreen"
      color="drawer"
      dense
    >
      <v-list
        dense
        flat
        class="py-0"
      >
        <!-- Compte -->
        <v-list-item
          v-if="authenticated"
          class="px-2"
        >
          <v-list-item-avatar>
            <v-avatar
              color="primary"
              size="40"
              class="white--text"
            >
              {{
                getName
                  .split(" ")
                  .map((x) => x[0])
                  .join("")
              }}
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              {{ getName }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ getUserName }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-else>
          <v-list-item-avatar>
            <v-icon class="justify-start">
              mdi-account
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Non connecté</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider />
        <v-list-item-group color="primary">
          <template v-for="item in mainmenu">
            <v-divider
              v-if="item.divider"
              :key="item.title + 'div'"
            />
            <v-list-item
              :key="item.title"
              link
              :to="item.to"
              @click="item.action && item.action()"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    miniScreen() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    authenticated() {
      return this.$store.getters.authenticated;
    },
    getName() {
      return this.$store.getters.getName;
    },
    getUserName() {
      return this.$store.getters.getUserName;
    },
    getShowFopsTab() {
      return this.$store.getters.getShowFopsTab;
    },
    getShowEventsTab() {
      return this.$store.getters.getShowEventsTab;
    },
    getShowAdminTabs() {
      return this.$store.getters.getShowAdminTabs;
    },
    getShowManageLocationsTab() {
      return this.$store.getters.getShowManageLocationsTab;
    },
    mainmenu() {
      let menu = [];
      if (this.getShowFopsTab) {
        menu.push({ title: "Mes Fops", icon: "mdi-note-check", to: "/myfops" });
      }
      if (this.getShowEventsTab) {
        menu.push({
          title: "Mes évènements",
          icon: "mdi-clipboard-text-clock",
          to: "/myevents",
        });
      }
      menu.push(
        ...[
          {
            title: "Mes Groupes",
            icon: "mdi-account-multiple",
            to: "/mygroups",
          },
          { title: "Calendrier", icon: "mdi-calendar", to: "/calendar" },
        ]
      );
      if (this.getShowAdminTabs) {
        menu.push(
          {
            title: "Gestion des groupes",
            icon: "mdi-account-multiple-plus",
            to: "/groups/manage",
            divider: true,
          },
          {
            title: "Gestion des types de FOP",
            icon: "mdi-clipboard-edit",
            to: "/foptypes/manage",
          },
          {
            title: "Gestion des lieux",
            icon: "mdi-map-marker-plus",
            to: "/locations/manage",
          },
          {
            title: "Gestion des chemins de validation",
            icon: "mdi-account-details",
            to: "/fopsteps/manage",
          }
        );
      }
      menu.push({
        title: "A propos",
        icon: "mdi-information-outline",
        to: "/about",
        divider: true,
      });
      return menu;
    },
  },
  // methods: {
  //   becomeUsergroup(i) {
  //     this.axiosPost(process.env.VUE_APP_API_URL + "/debug/becomeUsergroup", {
  //       group: i,
  //     })
  //       .then(() => {
  //         this.$alert.$emit("snackbar", {
  //           message: "OK",
  //           status: "info",
  //         });
  //       })
  //       .catch((err) => {
  //         this.$alert.$emit("snackbar", {
  //           message: err,
  //           status: "error",
  //         });
  //       });
  //   },
  // },
};
</script>